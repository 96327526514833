@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Nunito", sans-serif;
}
.dashboard-sidebar::-webkit-scrollbar {
  display: none;
}
.table-container {
  overflow: auto; /* Ensure the table is scrollable */
}

.table-container::-webkit-scrollbar {
  width: 2px; /* Narrow scrollbar width */
  height: 4px; /* Narrow scrollbar height for horizontal scroll */
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background color */
}

.table-container::-webkit-scrollbar-thumb {
  background: #888; /* Thumb (scrollbar handle) color */
  border-radius: 4px; /* Rounded edges for the thumb */
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

/* For Firefox (uses scrollbar-width and scrollbar-color) */
.table-container {
  scrollbar-width: thin; /* Narrow scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb and track colors */
}

.multiselect-container {
  position: relative;
}

.multiselect-container .rmsc {
  --rmsc-main: #4299e1;
  --rmsc-hover: #ebf8ff;
  --rmsc-selected: #bee3f8;
  --rmsc-border: #a0aec0;
  --rmsc-gray: #4a5568;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 38px;
}

.multiselect-container .rmsc .dropdown-container {
  position: absolute;
  z-index: 10000;
  width: 100%;
  background-color: var(--rmsc-bg);
  border: 1px solid var(--rmsc-border);
  border-radius: var(--rmsc-radius);
}

.multiselect-container .rmsc .dropdown-content {
  position: relative;
  max-height: 300px;
  overflow-y: auto;
}

.EditorStyle{
  border: 1px solid gray;
}

.gradient-text {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(
    to right,
    #ff6b6b,
    #4ecdc4,
    #45b7d1,
    #96c93d,
    #ff6b6b
  );
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: shine 3s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}
input[type="datetime-local"]:not([value])::-webkit-datetime-edit {
  opacity: 0;
}

input[type="datetime-local"]:not([value])::-webkit-datetime-edit-fields-wrapper {
  opacity: 0;
}




/* Add this to your CSS */
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  margin-left: 0;
}

input[type="datetime-local"]::-webkit-datetime-edit-minute-field {
  -webkit-appearance: none;
  appearance: none;
}

/* Optional: Style the time picker dropdown */
input[type="datetime-local"]::-webkit-time-picker {
  min-height: 200px;
}



.btn {
  padding: 10px 20px;
  background-color: #36498f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #2c3e50;
}

.textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.timer-container {
  isolation: isolate;
  contain: content;
}

.timer-wrapper {
  contain: content;
  will-change: contents;
}


@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

.swiper {
  padding: 2rem 0;
  width: 100%;

}

.swiper-slide {
  width: 320px;
  height: auto;
  transition: transform 0.3s ease;

}

.swiper-button-next,
.swiper-button-prev {
  color: #9333ea !important;
}

.swiper-pagination-bullet-active {
  background: #9333ea !important;
}

.swiper-slide-active {
  transform: scale(1.1);
  z-index: 2;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  background-image: linear-gradient(
    to left,
    rgba(147, 51, 234, 0.15),
    rgba(147, 51, 234, 0)
  ) !important;
}


.mask-border {
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
}


.clamp-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}






@keyframes text-scale {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

.animate-border {
  animation: border-pulse 2s linear infinite;
}

.animate-pulse-scale {
  animation: text-scale 2s ease-in-out infinite;
  display: inline-block; /* Required for transform */
}



/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: color 0.2s;
}

.close-button:hover {
  color: #000;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: 1px solid #eee;
  gap: 10px;
}

/* Document Gallery Styles */
.document-gallery-container {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
}

.upload-section {
  margin-bottom: 20px;
}

.upload-label {
  display: inline-block;
  cursor: pointer;
}

.file-input {
  display: none;
}

.upload-button {
  display: inline-block;
  background-color: #4a90e2;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #3a80d2;
}

.upload-help {
  margin-top: 8px;
  color: #666;
  font-size: 13px;
}

.gallery-instructions {
  margin-bottom: 15px;
  color: #666;
  font-size: 14px;
}

.document-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.document-item {
  position: relative;
  width: 150px;
  height: 180px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: #f9f9f9;
}

.document-item.dragging {
  opacity: 0.5;
  transform: scale(0.95);
}

.document-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.document-container img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.pdf-preview {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #f0f0f0;
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #4a90e2;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.error-message {
  color: #d32f2f;
  font-size: 12px;
  text-align: center;
  padding: 5px;
}

.pdf-controls {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 3px 0;
}

.pdf-controls button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  padding: 0 5px;
}

.pdf-controls button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.pdf-controls span {
  font-size: 12px;
  margin: 0 5px;
}

.document-info {
  padding: 5px;
  text-align: center;
  font-size: 12px;
  color: #666;
  background-color: #fff;
  border-top: 1px solid #eee;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  z-index: 10;
}

.remove-btn:hover {
  background-color: rgba(255, 0, 0, 0.7);
}

.cancel-btn {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-btn:hover {
  background-color: #e5e5e5;
}

.upload-submit-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-submit-btn:hover {
  background-color: #3d9c40;
}

.upload-submit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .document-item {
    width: calc(50% - 10px);
  }
  
  .modal-content {
    width: 95%;
    max-height: 95vh;
  }
}




.essay-description-container {
  /* Basic styling */
  padding: 1rem;
  line-height: 1.6;

  /* Style paragraphs */
  p {
    margin-bottom: 1rem;
  }

  /* Style lists */
  ul, ol {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  /* Nested lists */
  ul ul, ol ul, ul ol, ol ol {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  /* Style headings */
  h3 {
    margin: 1.5rem 0 1rem;
    font-weight: 600;
  }

  /* Style emphasis */
  em {
    font-style: italic;
  }

  strong {
    font-weight: 600;
  }
}